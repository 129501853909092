html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  touch-action: none;
}

#root {
  overflow: auto;
}

body {
  position: fixed;
  overflow: hidden;
  overscroll-behavior-y: none;
  color: black;
  background: white;
}

.timeline {
  position: fixed;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  text-align: center;
  bottom: 0;
  transition: 250ms ease;
  -webkit-transition: 250ms ease;
}

.point {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  border: 3px solid rgba(0,0,0,0.4);
  /* backdrop-filter: blur(10px); */
  display: flex;
  flex-direction: column;
}

.point-title {
  padding-top: 1rem;
  font-size: 0.7rem;
  color: rgba(0,0,0,0.5);
  transform: translateX(-0.6rem);
}

.road {
  background-color: rgba(0,0,0,0.4);
  width: 40px;
  height: 2px;
  margin: 4.5px 0;
}

.w10 {
  width: 10px;
}
.w20 {
  width: 20px;
}
.w30 {
  width: 30px;
}
.w40 {
  width: 40px;
}
.w50 {
  width: 4.5%;
}
.w60 {
  width: 60px;
}
.w70 {
  width: 70px;
}
.w80 {
  width: 80px;
}
.w90 {
  width: 9%;
}
.w100 {
  width: 100px;
}
.w110 {
  width: 12%;
}

.get-in {
  mask-image: linear-gradient(to right, rgba(0,0,0,0) 25%, rgba(0,0,0,0.9));
  -webkit-mask-image: linear-gradient(to right, rgba(0,0,0,0) 25%, rgba(0,0,0,0.9));
}

.get-out {
  mask-image: linear-gradient(to right, rgba(0,0,0,0.9) 25%, rgba(0,0,0,0));
  -webkit-mask-image: linear-gradient(to right, rgba(0,0,0,0.9) 25%, rgba(0,0,0,0));
}


a {
  text-decoration: none;
  cursor: pointer;
  color: white;
}

a:visited {
  text-decoration: none;
}

.scale-down {
  transform: scale(0.8);
}

@media only screen and (max-width: 599px) {
  .timeline {
    display: none;
  }
}
