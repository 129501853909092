.info-path path{
  stroke: red;
  stroke-width: 1.5;
  fill: transparent;
  
}

.info-path {
  stroke-dasharray: 70 70;
  stroke-dashoffset: 70;
  opacity: 1;
  margin-top: 1.8rem;
  transform: scale(1.8);
  animation: dash 2s ease infinite;
}

.animate-path {
  stroke-dasharray: 1600;
  stroke-dashoffset: -1600;
  animation: animate-dash 1s ease-out forwards;
}

.animate-path path {
  stroke-width: 16;
  fill: transparent;
  stroke: rgba(0,0,0,0.2);
}

.rotate-this {
  transform: rotate3d(1,0,0,40deg);
}

.social-icons {
  border-top: 1px solid rgba(0,0,0,0.1);
  padding: 1rem 0 0.2rem 0;
  margin: 0.6rem 0 0 0;
}

.social-icons svg {
  padding: 0 1rem;
  transition: 250ms ease;
}

.social-icons svg:hover {
  transform: scale(1.1);
  opacity: 0.9;
  cursor: pointer;
}

.github-icon {
  fill: #24292E;
}
.dribbble-icon {
  fill: #EA4C89;
}
.linkedin-icon {
  fill: #0277B5;
}

@keyframes dash {
  0% {
    opacity: 1;
  }
  
  80% {
    opacity: 1;
    stroke-dashoffset: 0;
  }
  
  100% {
    opacity: 0;
    stroke-dashoffset: -70;
  }
}


@keyframes animate-dash {
  100% {
    stroke-dashoffset: 0;
  }
}

