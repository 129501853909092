
.loading-page {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: #171717;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  pointer-events: none;
}

.loading-bar-container {
  width: 160px;
  height: 3px;
  background: #272727;
}

.loading-bar {
  height: 3px;
  border-radius: 50px;
  background: white;
  transition: 1s ease;
}

.loading-data {
  display: inline-block;
  position: relative;
  font-variant-numeric: tabular-nums;
  margin-top: 1.8em;
  width: 100%;
  text-align: right;
  color: #f0f0f0;
  font-size: 0.6em;
}

.do-hide {
  animation: 0.3s disappear ease forwards;
}

@keyframes disappear {
  0% {opacity: 1}
  100% {opacity: 0}
}
