.mobile-controls {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.joystick {
  background: rgba(255,255,255,0.4);
  backdrop-filter: blur(12px);
  width: 160px;
  height: 160px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 350ms ease;
}

.thumb {
  background: rgba(10,10,10,0.7);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  /* transition: 150ms ease; */
  transform: translate3d(0,0,0);
}
