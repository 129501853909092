.card-container {
  font-family: 'Nunito', sans-serif;
  position: absolute;
  top: 0;
  right: 0;
  width: 420px;
  height: 100%;
  -webkit-user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-card {
  background: rgba(255,255,255,0.9);
  backdrop-filter: blur(12px);
  width: 360px;
  border-radius: 38px;
  -webkit-box-shadow: 0px 30px 26px 2px rgba(0,0,0,0.1); 
  box-shadow: 0px 30px 26px 2px rgba(0,0,0,0.1);
  opacity: 0;
  transform: translate3d(0, 80px, 0);
  transition: all 450ms ease !important;
}

.do-fade-in {
  animation: 0.6s fade-in ease-out forwards;
}

.do-fade-out {
  animation: 0.3s fade-out ease forwards;
}

.info-card h1 {
  text-align: center;
  padding: 1.2rem 2rem 0rem 2rem;
  margin: 0.6rem 0 0 0;
  font-size: 1.3rem;
  font-weight: 800;
}

.info-card h2 {
  font-size: 0.75rem;
  font-weight: 600 !important;
  /* border: 1px solid rgba(0,0,0,0.1); */
  border-top: 1px solid rgba(0,0,0,0.1);
  border-bottom: 1px solid rgba(0,0,0,0.1);
  /* border-radius: 50px; */
  color: rgba(0,0,0,0.5);
  letter-spacing: 0.1rem;
  padding: 0.2rem 0.4rem;
  margin: 0;
  font-weight: 800;
}

.year{
  padding: 1.2rem;
  margin: 0;
  display: flex;
  justify-content: center;
  justify-items: center;
}


.info-card p {
  padding: 0 2rem 0.8rem 2rem;
  margin: 0;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  text-align: justify;
  text-justify: inter-word;
}

.info-card > span {
  padding: 0.2rem 2rem;
}

@keyframes fade-in {
  0% { opacity: 0; transform: translate3d(0, 80px, 0); }
  100% { opacity: 1; transform: translate3d(0, 0, 0); }
}

@keyframes fade-out {
  0% { opacity: 1; }
  100% { opacity: 0; transform: scale(1.2); }
}

.info-card .icon-container {
  height: auto;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.icon-container .icon {
  fill: rgba(0,0,0,0.17);
  width:36px;
}

.icon-container .keywords {
  width: 84%;
  max-width: 84%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 0.8rem;
}

.icon-container .keywords span {
  background-color: rgba(0,0,0,0.1);
  color: rgba(0,0,0,0.7);
  height: 12px;
  display: flex;
  padding: 0.5rem 0.5rem;
  margin: 0 0.2rem;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  border-radius: 50px;
}

.info-url {
  background: black;
  padding: 0.8rem 1.3rem;
  border-radius: 63px;
  font-size: 0.9rem;
  margin: 1rem;
}

.text-shine {
  background: linear-gradient(to right, rgba(0,0,0,0.15), rgba(0,0,0,1), rgba(0,0,0,0.1));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  background-size: 200% auto;
  animation: textShine 3.5s linear infinite alternate;
}

@keyframes textShine {Ø
  to {
    background-position: -200%;
  }
}

@media only screen and (max-width: 599px) {
  .card-container {
    width: 100%;
    align-items: flex-start;
  }
  
  .info-card {
    width: 95%;
    background: rgba(255,255,255,0.3);
    backdrop-filter: blur(20px);
  }
  
  .info-card h1 {
    padding: 0;
  }
}

